/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react-hooks/exhaustive-deps */
import { Fade } from 'react-awesome-reveal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Gallery from '../components/Gallery';
import Photo1 from '../assets/gallery/photo-1.jpg';
import Photo2 from '../assets/gallery/photo-2.jpg';
import Photo4 from '../assets/gallery/photo-4.jpg';
import Photo5 from '../assets/gallery/photo-5.jpg';
import Photo6 from '../assets/gallery/photo-6.jpg';
import Photo7 from '../assets/gallery/photo-7.jpg';
import Photo8 from '../assets/gallery/photo-8.jpg';
import Photo9 from '../assets/gallery/photo-9.jpg';
import Photo10 from '../assets/gallery/photo-10.jpg';
import Photo11 from '../assets/gallery/photo-11.jpg';
import Photo12 from '../assets/gallery/photo-12.jpg';
import Photo13 from '../assets/gallery/photo-13.jpg';
import IconDownload from '../assets/svg/icon-download.svg';
import IconArrowLeft from '../assets/svg/icon-arrow-left.svg';
import IconArrowRight from '../assets/svg/icon-arrow-right.svg';
import Button from '../components/Button';
import useKeyPress from '../utils/useKeyPress';

const PreviewImages: string[] = [
  Photo1,
  Photo8,
  Photo2,
  Photo6,
  Photo7,
  Photo4,
  Photo5,
  Photo9,
  Photo10,
  Photo11,
  Photo12,
  Photo13,
];

export default function Preview() {
  const previewRef = useRef<HTMLDivElement>();
  const [preview, setPreview] = useState<number | undefined>();
  const escapePressed = useKeyPress('Escape');
  const leftPressed = useKeyPress('ArrowLeft');
  const rightPressed = useKeyPress('ArrowRight');
  const previewImage = useMemo<string>(
    () => PreviewImages[preview] ?? undefined,
    [preview],
  );

  const onArrowRight = () => {
    if (preview !== undefined && preview + 1 < PreviewImages.length) {
      setPreview(preview + 1);
    }
  };

  const onArrowLeft = () => {
    if (preview !== undefined && preview - 1 >= 0) {
      setPreview(preview - 1);
    }
  };

  useEffect(() => {
    if (escapePressed) {
      setPreview(undefined);
    }
  }, [escapePressed, setPreview]);

  useEffect(() => {
    if (leftPressed) {
      onArrowLeft();
    }
  }, [leftPressed]);

  useEffect(() => {
    if (rightPressed) {
      onArrowRight();
    }
  }, [rightPressed]);

  const setGallery = useCallback(
    (index: number) => {
      setPreview(index);
      previewRef.current?.focus();
    },
    [previewRef],
  );

  return (
    <>
      <div className="Preview">
        <div className="Anchor" id="preview" />
        <Fade direction="up" triggerOnce>
          <div className="Container">
            <h2>Ukázky aplikací identity</h2>
            <p>
              Zajímá vás, jak bude nová vizuální identita vypadat v praxi?
              Prohlédněte si vybrané ukázky aplikací.
            </p>
          </div>
        </Fade>
        <Fade delay={500} triggerOnce>
          <Gallery
            className="Preview__gallery"
            photos={PreviewImages}
            setPreview={setGallery}
          />
        </Fade>
        <Fade delay={500} triggerOnce>
          <a href="/download/amf-manual.pdf" download>
            <Button variant="primary">
              <IconDownload /> Stáhnout manuál
            </Button>
          </a>
        </Fade>
      </div>
      <div tabIndex={-1} className="Gallery__previewFocusable" ref={previewRef}>
        {previewImage && (
          <div className="Gallery__preview">
            <div className="Gallery__previewOverlay" />
            <Button
              onClick={() => setPreview(undefined)}
              className="Gallery__previewButton"
              variant="transparent"
            >
              Zavřít
            </Button>
            <div
              className="Gallery__previewWrapper"
              onClick={() => setPreview(undefined)}
            >
              <img src={previewImage} alt="" />
            </div>
            {preview - 1 >= 0 && (
              <div
                className="Gallery__arrow Gallery__arrow--left"
                onClick={onArrowLeft}
              >
                <IconArrowLeft />
              </div>
            )}
            {preview + 1 < PreviewImages.length && (
              <div
                className="Gallery__arrow Gallery__arrow--right"
                onClick={onArrowRight}
              >
                <IconArrowRight />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
