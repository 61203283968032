import { Fade } from 'react-awesome-reveal';

import IconGround from '../assets/svg/icon-language-ground.svg';
import IconCircle from '../assets/svg/icon-language-circle.svg';
import IconPlayers from '../assets/svg/icon-language-players.svg';

export default function Language() {
  return (
    <div className="Language">
      <div className="Anchor" id="visual" />
      <Fade triggerOnce>
        <div className="Container">
          <h2>Vizuální styl</h2>
          <p>
            Vizuální styl Malého fotbalu tvoří kromě loga, barev a typografie,
            také podkladové plochy, tvořené třemi prvky. Finální kompozice
            utváří unikátní vizuální dojem.
          </p>
          <div className="Language__list">
            <div className="Language__item">
              <IconGround />
              <div>
                <h3>Dva světy</h3>
                <p>
                  Podklad je tvořen ze dvou barevných polovin, které symbolizují
                  dvě poloviny hřiště, dva týmy a také dva světy malého fotbalu
                  – elitního a amatérského, hraného pro radost.
                </p>
              </div>
            </div>
            <div className="Language__item">
              <IconCircle />
              <div>
                <h3>Středový kruh</h3>
                <p>
                  Dva půlkruhy v&nbsp;inverzních barvách symbolizují středový
                  kruh a dva poločasy o 30 minutách.
                </p>
              </div>
            </div>
            <div className="Language__item">
              <IconPlayers />
              <div>
                <h3>12 hráčů</h3>
                <p>
                  Kompozici doplňují symboly
                  12&nbsp;křížků&nbsp;=&nbsp;12&nbsp;hráčů, tvořící dva týmy
                  malého fotbalu.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
