import IconBadge from '../assets/svg/icon-badge.svg';
import IconCrossFilled from '../assets/svg/icon-cross.svg';
import IconCrossOutline from '../assets/svg/icon-cross-outline.svg';

export default function CrossDivider() {
  return (
    <div className="CrossDivider">
      <IconBadge className="CrossDivider__badge" />
      <div className="CrossDivider__items">
        <IconCrossOutline
          className="CrossDivider__item"
          width={32}
          height={32}
        />
        <IconCrossOutline
          className="CrossDivider__item"
          width={32}
          height={32}
        />
        <IconCrossOutline
          className="CrossDivider__item"
          width={32}
          height={32}
        />
        <IconCrossOutline
          className="CrossDivider__item"
          width={32}
          height={32}
        />
        <IconCrossOutline
          className="CrossDivider__item"
          width={32}
          height={32}
        />
        <IconCrossFilled
          className="CrossDivider__item CrossDivider__item--filled"
          width={32}
          height={32}
        />
      </div>
    </div>
  );
}
