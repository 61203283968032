import { Fade } from 'react-awesome-reveal';

import CrossDivider from '../components/CrossDivider';
import StoryFlag from '../assets/svg/icon-story-flag.svg';
import StoryForm from '../assets/svg/icon-story-form.svg';
import StorySymbol from '../assets/svg/icon-story-symbol.svg';

export default function Story() {
  return (
    <div className="Container">
      <div className="Story">
        <div className="Anchor" id="story" />
        <CrossDivider />
        <Fade direction="up" triggerOnce>
          <h2>Cesta k nové identitě</h2>
          <p>
            <strong>
              Jít s dobou, stanovit jasnou a originální vizuální tvář, vytvořit
              systém značek a společně se sportovními úspěchy dále rozvíjet malý
              fotbal.
            </strong>{' '}
            Tyto důvody vedly k vytvoření nové vizuální identity malého fotbalu,
            kterou vám představujeme.
          </p>
        </Fade>
        <div className="Story__componentList">
          <div className="Story__component">
            <Fade direction="up" triggerOnce>
              <div>
                <h3>Vlaječka</h3>
                <p>
                  Jako obrysový tvar loga byl zvolen grafický prvek štítu
                  inspirovaný fotbalovou vlaječkou, kterou si týmy předávají
                  před utkáním.
                </p>
                <p>
                  Vlaječka symbolizuje fair-play, sportovní přátelství,
                  spolupráci i tradici. Všechny tyto hodnoty jsou typické i pro
                  malý fotbal. Svým původním významem erbovního štítu ale
                  vyjadřuje také bojovnost a odvahu.
                </p>
              </div>
            </Fade>
            <Fade delay={500} triggerOnce>
              <StoryFlag className="Story__icon" />
              <div className="Story__symbol">+</div>
            </Fade>
          </div>
          <div className="Story__component">
            <Fade direction="up" triggerOnce>
              <div>
                <h3>Formace</h3>
                <p>
                  Šestice křížků znázorňuje tradiční formaci 5+1, která je pro
                  malý fotbal typická.
                </p>
                <p>
                  Hráče označujeme formou křížku – např. tak, jak je můžeme
                  vidět na trenérských tabulích při nákresech herních situací.
                </p>
              </div>
            </Fade>
            <Fade delay={500} triggerOnce>
              <StoryForm className="Story__icon" />
              <div className="Story__symbol">=</div>
            </Fade>
          </div>
          <div className="Story__component">
            <Fade direction="up" triggerOnce>
              <div>
                <h3>Symbol</h3>
                <p>
                  Výsledným symbolem je vizuálně silný a sebevědomý znak loga
                  Malého fotbalu, který propojuje všechny přidružené subbrandy
                  v&nbsp;rámci rodiny značek.
                </p>
                <p>
                  Nová identita Malého fotbalu deklaruje počátek nové moderní
                  etapy tohoto sportu v&nbsp;Česku.
                </p>
              </div>
            </Fade>
            <Fade delay={500} triggerOnce>
              <StorySymbol className="Story__icon" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
