import classNames from 'classnames';
import { MouseEventHandler, useEffect, useState } from 'react';
import useWindowScroll from '@react-hook/window-scroll';

import IconBrand from '../assets/svg/icon-brand-maly-fotbal.svg';

export const NAVIGATION_SCROLL_OFFSET = 80;

function isInViewport(element: HTMLElement): boolean {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= -(window.innerHeight / 2) &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export interface NavigationItem {
  title: string;
  id: string;
}

interface NavigationProps {
  items: NavigationItem[];
}

export default function Navigation({ items }: NavigationProps) {
  const [background, setBackground] = useState(false);
  const [selected, setSelected] = useState<string>('');
  const offset = useWindowScroll(60);

  useEffect(() => {
    setBackground(offset > NAVIGATION_SCROLL_OFFSET);
  }, [offset]);

  useEffect(() => {
    setSelected('');
    for (let i = 0; i < items.length; i += 1) {
      if (
        isInViewport(document.getElementById(items[i].id)) &&
        offset > window.innerHeight / 2
      ) {
        setSelected(items[i].id);
        break;
      }
    }
  }, [offset, items, setSelected]);

  const onClick: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    const id = event.currentTarget.href.split('#').slice(-1).pop();
    if (!id) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className={classNames('Navigation', {
        'Navigation--withBackground': background,
      })}
    >
      <div className="Container">
        <div className="Navigation__content">
          <a href="/#" onClick={onClick}>
            <IconBrand className="Navigation__brand" />
          </a>
          <ul className="Navigation__menu">
            {items.map(({ id, title }) => (
              <li
                key={id}
                className={classNames('Navigation__menuItem', {
                  'Navigation__menuItem--selected': id === selected,
                })}
              >
                <a
                  href={`#${id}`}
                  onClick={onClick}
                  className="Navigation__menuItemLink"
                >
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
