import Navigation from '../components/Navigation';
import Hero from '../sections/Hero';
import Story from '../sections/Story';
import Language from '../sections/Language';
import Header from '../components/Header';
import Brands from '../sections/Brands';
import Preview from '../sections/Preview';
import Testimonials from '../sections/Testimonials';
import FAQ from '../sections/FAQ';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <div className="Layout">
      <Header />
      <Navigation
        items={[
          { id: 'story', title: 'Cesta' },
          { id: 'visual', title: 'Vizuální styl' },
          { id: 'family', title: 'Rodina značek' },
          { id: 'preview', title: 'Ukázky' },
          { id: 'testimonials', title: 'Ohlasy' },
          { id: 'faq', title: 'Časté otázky' },
        ]}
      />
      <Hero />
      <Story />
      <Language />
      <Brands />
      <Preview />
      <Testimonials />
      <FAQ />
      <Footer />
    </div>
  );
}
