/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactNode, useState } from 'react';
import { Fade } from 'react-awesome-reveal';

import IconExpand from '../assets/svg/icon-expand.svg';
import IconCollapse from '../assets/svg/icon-collapse.svg';

interface FAQItemProps {
  title: ReactNode;
  content: ReactNode;
}

function FAQItem({ title, content }: FAQItemProps) {
  const [expand, setExpand] = useState(false);

  return (
    <div className="FAQ__item">
      <div className="FAQ__itemTitle" onClick={() => setExpand(!expand)}>
        <h3>{title}</h3>
        {expand ? <IconCollapse /> : <IconExpand />}
      </div>
      {expand && <div className="FAQ__itemContent">{content}</div>}
    </div>
  );
}

export default function FAQ() {
  return (
    <div className="FAQ">
      <div className="Anchor" id="faq" />
      <div className="Container">
        <Fade triggerOnce>
          <h2>Často kladené otázky</h2>
        </Fade>
        <div className="FAQ__list">
          <Fade direction="up" triggerOnce>
            <FAQItem
              title={
                <>
                  Proč jste vytvořili novou vizuální identitu? Jaké k tomu vedly
                  důvody?
                </>
              }
              content={
                <p>
                  Malý fotbal a jeho produkty doposud postrádaly jasný a
                  rozpoznatelný vizuální styl. Chyběla struktura značek, která
                  by dílčí brandy propojovala. Limitující byla absence
                  ikonických prvků, které by šlo využívat v komunikaci bez
                  doprovodného textu. Chybějící komplexní grafický manuál byl
                  překážkou i pro dílčí činnosti, které jsou v&nbsp;zásadě
                  podmíněně jeho existencí – např. vizuální styl webových
                  stránek, komunikace na sociálních sítích, apod. Malý fotbal má
                  navíc ambici i potenciál rozvíjet se také v&nbsp;marketingové
                  a obchodní oblasti, k&nbsp;čemuž potřeboval vytvořit novou
                  moderní vizuální identitu, která bude atraktivní nejen pro
                  potenciální partnery, ale i širokou veřejnost.
                </p>
              }
            />
            <FAQItem
              title={<>Jak dlouho se nová identita připravovala?</>}
              content={
                <p>
                  Nová identita se připravovala přibližně od září minulého roku.
                  Cesta k&nbsp;finální podobě tedy trvala přibližně šest měsíců.
                </p>
              }
            />
            <FAQItem
              title={<>Kdo novou vizuální identitu připravoval?</>}
              content={
                <p>
                  Novou vizuální identitu pro Malý fotbal připravila
                  sportovně-marketingová agentura 2Score, která patří mezi
                  špičku v&nbsp;českém sportovním marketingu a mezi jejíž
                  klienty patří například AC Sparta Praha, Ligová fotbalová
                  asociace nebo Fotbalová asociace ČR. V létě 2020 agentura
                  2Score zvítězila ve výběrovém řízení na poskytovatele
                  marketingových služeb a od té doby trvá vzájemná spolupráce s
                  Asociací malého fotbalu. Interní tým AMF pak tvořili Jan
                  Pinkava, CEO Asociace malého fotbalu, Petr Brejla,
                  sportovně-technický ředitel a další členové správní rady,
                  kteří návrhy připomínkovali. Novou identitu schválila také
                  Valná hromada AMF.
                </p>
              }
            />
            <FAQItem
              title={<>Proč jsou v&nbsp;logu křížky?</>}
              content={
                <p>
                  Zvolený symbol křížku má symbolizovat hráče - stejně jako
                  např. na trenérské tabuli při nákresech herních situací.
                  Křížek je také symbolem pro souboj (zápas) nebo rovnováhu
                  (fair-play).
                </p>
              }
            />
            <FAQItem
              title={<>Proč jste vybrali zrovna takové barvy?</>}
              content={
                <p>
                  Již od prvních konceptů jsme měli jasno, že chceme vybrat
                  takové barvy, které budou tvořit moderní, atraktivní a svěží
                  celek a které budou vzájemně fungovat. Volba barev byla jedním
                  z klíčových prvků zejména pro odlišení jednotlivých značek.
                  Modrá barva byla součástí předchozích značek Malého fotbalu.
                  Jako primární jsme proto zvolili taktéž modrou, avšak
                  v&nbsp;jiném, modernějším odstínu. Ostatní barvy jsou pak
                  spojeny primárně s jednotlivými produkty Malého fotbalu –
                  Superliga ve žluté/zlaté barvě, Český národní pohár
                  v&nbsp;šedé/stříbrné a reprezentace a logo Asociace pak
                  v&nbsp;barvě červené, které společně s&nbsp;modrou představují
                  národní barvy.
                </p>
              }
            />
            <FAQItem
              title={<>Konzultovali jste to s někým?</>}
              content={
                <p>
                  Ano, návrhy jsme konzultovali i s dalšími odborníky, kteří
                  mají zkušenost s rebrandingem sportovních asociací či značek.
                </p>
              }
            />
            <FAQItem
              title={<>Kdy se začne nová identita používat?</>}
              content={
                <p>
                  Představením nové identity začíná její okamžitá aplikace.
                  Všechno má však svůj vývoj, proto je možné a přirozené, že se
                  můžete někde ještě se staršími grafickými prvky setkávat.
                </p>
              }
            />
            <FAQItem
              title={<>Není nová identita až příliš moderní a novátorská?</>}
              content={
                <p>
                  Respektujeme názor, že se nová identita může někomu zdát až
                  příliš moderní nebo novátorská. Malý fotbal však má ambice
                  růst a být více atraktivní, a to nejen z hlediska sportu jako
                  takového, ale i směrem k&nbsp;veřejnosti, partnerům i médiím.
                  Jsme přesvědčeni, že nová identita splňuje měřítka dnešní
                  moderní a digitální doby a jednoznačně ukazuje, že malý fotbal
                  to myslí se svým rozvojem vážně. A zbrusu nová a moderní
                  identita je jedním ze startovních kroků.
                </p>
              }
            />
            <FAQItem
              title={<>Moc se mi to nelíbí - co mám dělat?</>}
              content={
                <p>
                  Zkuste si, prosím, ještě jednou pročíst důvody, které malý
                  fotbal k&nbsp;tomuto kroku vedly. Podívejte se na popis
                  jednotlivých prvků nové vizuální identity. Je možné, že ani
                  pak se vám nová identita líbit nebude, což respektujeme. Každé
                  hodnocení je velice subjektivní a jakákoliv změna tohoto typu
                  vyvolává různé reakce. Mnohdy je nejlepším řešením dát tomu
                  čas a s&nbsp;odstupem vidět, jak identita reálně funguje v
                  praxi.
                </p>
              }
            />
          </Fade>
        </div>
      </div>
    </div>
  );
}
