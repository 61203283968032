import { Fade } from 'react-awesome-reveal';

import BrandMalyFotbal from '../assets/svg/icon-brand-maly-fotbal.svg';
import BrandRepre from '../assets/svg/icon-brand-repre.svg';
import BrandSuperliga from '../assets/svg/icon-brand-superliga.svg';
import BrandSuperfinale from '../assets/svg/icon-brand-superfinale.svg';
import BrandCNP from '../assets/svg/icon-brand-cnp.svg';
import BrandAMF from '../assets/svg/icon-brand-amf.svg';
import BrandAMCR from '../assets/svg/icon-brand-amcr.svg';

export default function Brands() {
  return (
    <div className="Brands">
      <div className="Anchor" id="family" />
      <div className="Container">
        <Fade triggerOnce>
          <h2>Rodina značek</h2>
          <p>
            Rodina značek Malého fotbalu se skládá ze tří skupin logotypů.{' '}
            <strong>Primárním</strong>&nbsp;brandem je Malý fotbal, pod kterým
            jsou sdruženy značky <strong>sportovní</strong> - reprezentace,
            Superliga a Český národní pohár. Pro{' '}
            <strong>administrativní a oficiální komunikaci</strong> je rodina
            značek doplněna logem asociace.
          </p>
          <div className="Brands__tree">
            <BrandMalyFotbal className="Brands__item Brands__item--large" />
            <div className="Brands__cols">
              <div className="Brands__col">
                <BrandRepre className="Brands__item" />
              </div>
              <div className="Brands__col">
                <BrandSuperliga className="Brands__item" />
                <BrandSuperfinale className="Brands__item" />
              </div>
              <div className="Brands__col">
                <BrandCNP className="Brands__item" />
              </div>
              <div className="Brands__col">
                <BrandAMF className="Brands__item" />
                <BrandAMCR className="Brands__item" />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
