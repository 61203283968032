import Head from 'next/head';
import { GTag } from '@deptno/gtag-react';

export default function Header() {
  return (
    <Head>
      <script
        type="text/javascript"
        charSet="UTF-8"
        src="//cdn.cookie-script.com/s/6ef21f9bb351de30290bc0fe836fecd8.js"
      />
      <title>Malý fotbal v novém</title>
      <meta
        name="description"
        content="Malý fotbal představuje novou vizuální identitu! Moderní a dynamickou, tak jako je sport samotný."
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <GTag id="G-GWKF1B33RZ" />
    </Head>
  );
}
