import { useState } from 'react';

import IconPlay from '../assets/svg/icon-play.svg';
import Button from '../components/Button';
import Video from '../components/Video';

export default function Hero() {
  const [video, setVideo] = useState(false);

  return (
    <>
      <div className="Hero">
        <div className="Container">
          <h1 className="Hero__heading">
            Malý fotbal představuje novou vizuální identitu
          </h1>
          <p className="Hero__lead">
            Moderní a dynamickou, stejně jako je náš sport!
          </p>
          <Button
            variant="primary"
            className="Hero__button"
            onClick={() => setVideo(true)}
          >
            <IconPlay /> Zhlédnout video
          </Button>
        </div>
      </div>
      {video && <Video videoId="384_BGwgLEM" onClose={() => setVideo(false)} />}
    </>
  );
}
