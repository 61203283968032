import SocialFacebook from '../assets/svg/icon-social-facebook.svg';
import SocialInstagram from '../assets/svg/icon-social-instagram.svg';
import Brand2Score from '../assets/svg/brand-2score.svg';
import BrandElasticle from '../assets/svg/brand-elasticle.svg';
import IconFooter from '../assets/svg/icon-footer.svg';

export default function Footer() {
  return (
    <div className="Footer">
      <IconFooter className="Footer__icon" />
      <div className="Container">
        <div className="Footer__content">
          <div className="Footer__copy">
            <p>&copy; {new Date().getFullYear()} Asociace Malého Fotbalu</p>
            <div className="Footer__social">
              <a
                href="https://www.facebook.com/malyfotbal1"
                target="_blank"
                className="Footer__socialLink"
              >
                <SocialFacebook />
              </a>
              <a
                href="https://www.instagram.com/malyfotbal/"
                target="_blank"
                className="Footer__socialLink"
              >
                <SocialInstagram />
              </a>
            </div>
          </div>
          <div className="Footer__author">
            <span>Identita</span>
            <a href="https://www.2score.cz/" target="_blank">
              <Brand2Score />
            </a>
          </div>
          <div className="Footer__author">
            <span>Web</span>
            <a href="https://www.elasticle.cz/" target="_blank">
              <BrandElasticle />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
