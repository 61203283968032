import YouTube from 'react-youtube';
import { Fade } from 'react-awesome-reveal';

export default function Testimonials() {
  return (
    <div className="Testimonials">
      <div className="Anchor" id="testimonials" />
      <div className="Container">
        <Fade triggerOnce>
          <h2>Ohlasy k nové identitě</h2>
          <p>Co si o nové identitě myslí osobnosti ze světa Malého fotbalu?</p>
          <YouTube
            videoId="T7t3yg04h1s"
            className="Testimonials__videoPlayer"
            containerClassName="Testimonials__video"
            opts={{
              width: '100%',
              height: '100%',
            }}
          />
        </Fade>
      </div>
    </div>
  );
}
