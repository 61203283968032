import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'primary' | 'transparent';
}

export default function Button({
  variant,
  className,
  children,
  ...props
}: ButtonProps) {
  return (
    <button
      type="button"
      className={classNames('Button', `Button--${variant}`, className)}
      {...props}
    >
      {children}
    </button>
  );
}
