/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import {
  UIEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import IconArrowLeft from '../assets/svg/icon-arrow-left.svg';
import IconArrowRight from '../assets/svg/icon-arrow-right.svg';
import useWindowSize from '../utils/useWindowSize';

import Button from './Button';

interface GalleryItem {
  photo1?: string;
  photo1Index?: number;
  photo2?: string;
  photo2Index?: number;
  photo3?: string;
  photo3Index?: number;
}

function rearrangePhotos(photos: string[]): GalleryItem[] {
  const result: GalleryItem[] = [];
  for (let i = 0; i < photos.length; i += 3) {
    result.push({
      photo1: photos[i],
      photo1Index: i,
      photo2: photos[i + 1],
      photo2Index: i + 1,
      photo3: photos[i + 2],
      photo3Index: i + 2,
    });
  }

  return result;
}

interface GalleryProps {
  photos: string[];
  setPreview: (index: number) => void;
  className?: string;
}

export default function Gallery({
  className,
  photos,
  setPreview,
}: GalleryProps) {
  const scrollRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();
  const [width, setWidth] = useState<number | undefined>();
  const [left, setLeft] = useState(true);
  const [right, setRight] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    const style = window.getComputedStyle(containerRef.current);
    const padding = parseFloat(style.getPropertyValue('padding-left')) || 0;
    setWidth(contentRef.current.clientWidth + padding * 2);
  }, [contentRef, containerRef, windowSize.width]);

  const onScroll: UIEventHandler<HTMLDivElement> = useCallback(() => {
    const style = window.getComputedStyle(containerRef.current);
    const padding = parseFloat(style.getPropertyValue('padding-left')) || 0;
    setLeft(scrollRef.current.scrollLeft === 0);
    setRight(
      scrollRef.current.scrollLeft + scrollRef.current.clientWidth >=
        contentRef.current.clientWidth + padding,
    );
  }, [contentRef, scrollRef, containerRef]);

  const move = useCallback(
    (direction: 'left' | 'right') => {
      const { scrollLeft } = scrollRef.current;
      if (direction === 'right') {
        scrollRef.current.scrollTo({
          left: scrollLeft + scrollRef.current.clientWidth / 2,
          behavior: 'smooth',
        });
      } else {
        scrollRef.current.scrollTo({
          left: scrollLeft - scrollRef.current.clientWidth / 2,
          behavior: 'smooth',
        });
      }
    },
    [scrollRef],
  );

  return (
    <>
      <div className={classNames('Gallery__wrapper', className)}>
        <Button
          tabIndex={-1}
          className={classNames('Gallery__button Gallery__button--left', {
            'Gallery__button--hidden': left,
          })}
          variant="transparent"
          onClick={() => move('left')}
        >
          <IconArrowLeft />
        </Button>
        <Button
          tabIndex={-1}
          className={classNames('Gallery__button Gallery__button--right', {
            'Gallery__button--hidden': right,
          })}
          variant="transparent"
          onClick={() => move('right')}
        >
          <IconArrowRight />
        </Button>
        <div ref={scrollRef} className="Gallery" onScroll={onScroll}>
          <div
            ref={containerRef}
            className="Gallery__container"
            style={{ width }}
          >
            <div ref={contentRef} className="Gallery__content">
              {rearrangePhotos(photos).map((item, key) => (
                <div key={key} className="Gallery__section">
                  {item.photo1 && (
                    <div className="Gallery__item">
                      <img
                        onClick={() => setPreview(item.photo1Index)}
                        src={item.photo1}
                        alt=""
                      />
                    </div>
                  )}
                  <div className="Gallery__item Gallery__item--half">
                    {item.photo2 && (
                      <img
                        onClick={() => setPreview(item.photo2Index)}
                        src={item.photo2}
                        alt=""
                      />
                    )}
                    {item.photo3 && (
                      <img
                        onClick={() => setPreview(item.photo3Index)}
                        src={item.photo3}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
