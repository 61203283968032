/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import YouTube from 'react-youtube';
import { useEffect } from 'react';

import useKeyPress from '../utils/useKeyPress';

import Button from './Button';

interface VideoProps {
  videoId: string;
  onClose?: () => void;
}

export default function Video({ videoId, onClose }: VideoProps) {
  const escapePressed = useKeyPress('Escape');

  useEffect(() => {
    if (escapePressed) {
      onClose();
    }
  }, [escapePressed, onClose]);

  return (
    <div className="Video">
      <div className="Video__overlay" onClick={() => onClose()} />
      <div className="Video__player">
        <Button
          onClick={onClose}
          className="Video__button"
          variant="transparent"
        >
          Zavřít
        </Button>
        <YouTube
          videoId={videoId}
          className="Video__playerYoutube"
          opts={{
            width: '100%',
            height: '100%',
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </div>
    </div>
  );
}
